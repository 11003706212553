.social,
.email-wrapper {
	display: flex;
	padding-bottom: 20px;
}

.social a,
.email-wrapper a {
	text-decoration: none;
}

.social-icon {
	font-size: 15px;
	color: var(--secondary-color);
}

.social-text {
	color: var(--secondary-color);
	font-weight: 600;
	font-size: 14px;
}

.email::before {
	content: "";
	display: block;
	padding-top: 20px;
	border-top: 3px solid #f4f4f5;
	margin-top: 20px;
}

.email-wrapper {
	padding-top: 20px;
}

/*  */
.social-link {
	width: 100%;
	padding: 1rem;
	/* border-bottom: 1px solid lightgray; */
}

.social-link:hover {
	background-color: #f4f4f5;
}
