.contact-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.contact-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
}

.contact-subtitle {
	width: 100% !important;
}

.contact-container {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-top: 120px;
}

.socials-container {
	display: flex;
	flex-direction: column;
	margin-top: 80px;
}

.contact-socials {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

@media screen and (max-width: 600px) {
	.contact-socials {
		width: 100% !important;
		flex-direction: column !important;
		align-items: flex-start !important;
	}
}

.border {
	border: 1px solid red;
}

.flex {
	display: flex;
	flex-direction: row;
}

.flex > * {
	flex: 1;
}

.flex-vertical {
	flex-direction: column;
}

.custom-gap-1 {
	gap: 1rem;
}

.flex-space-between {
	justify-content: space-between;
	align-items: flex-start;
}

.mt-1 {
	margin-top: 1rem;
}

@media screen and (max-width: 600px) {
	.contact-form {
		width: 100%;
		padding: 0;
	}
}
